@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NeueMachina";
  src: local("NeueMachina"),
    url(./fonts/NeueMachina-Ultrabold.ttf) format("truetype");
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-image: linear-gradient(80deg, rgb(104, 18, 37), rgb(3, 14, 126));
  z-index: -10;
  color: white;
  background-image: url(./bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.btn-white-violet {
  @apply bg-transparent hover:bg-violet-700 text-white font-semibold hover:text-white py-2 px-4 border border-white hover:border-transparent rounded;
}

.branding {
  font-family: "NeueMachina";
  font-size: 2rem;
}

.signin-modal a {
  text-decoration: underline;
  font-weight: bold;
}

.footer-msg {
  font-family: "NeueMachina";
  font-size: 1rem;
}

.footer-msg a:hover {
  text-decoration: underline;
}

.interactive-text {
  font-family: "NeueMachina";
}

.card-header {
  background-color: rgba(0, 0, 0, 0.5);
}

.mockup {
  width: auto;
  height: auto;
  max-width: 100%;
  min-height: 400px;
  max-height: 700px;
}

nav li button:hover {
  text-decoration: underline;
}
